<template>
  <div class="eqmlog">
    <el-row :gutter="0" class="search" align="middle" type="flex">
      <el-col :span="2" class="search_item">设备编号:</el-col>
      <el-col :span="3" class="search_item">
         <el-select
        v-if="userRoleId === '0'"
         v-model="id" clearable placeholder="请选择设备"
          @change="userselecteqm($event)"
         >
          <el-option
            v-for="item in useroptions"
            :key="item.id"
            :label="item.text"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-cascader
         v-if="userRoleId === '1' || userRoleId === '2'"
          :options="options"
          @change="handleChange($event)"
          :props="optionProps"
          clearable
          v-model="defaultval"
        ></el-cascader
      ></el-col>
      <el-col :span="1" class="search_item">时间:</el-col>
      <el-col :span="6" class="search_item">
        <el-date-picker
          v-model="value2"
          value-format="yyyy-MM-dd"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker
      ></el-col>

      <el-col :span="2" :offset="10" class="search_item"
        ><el-button type="primary" style="backgroundColor:#0076F6;" @click="serachlog()">查询</el-button></el-col
      >
    </el-row>
    <div>
      <el-table
       v-loading="loading"
    element-loading-text="正在刷新数据"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="tableData"
        border
        align="center"
        stripe
        style=" color: #161718"
        height="720"
      >
        <el-table-column v-if="userRoleId === '1' || userRoleId === '2'" prop="company" label="公司" align="center">
        </el-table-column>
        <el-table-column
          prop="code"
          label="设备编号"
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column prop="location" label="位置" align="center" >
        </el-table-column>
        <!-- <el-table-column v-if="userRoleId === '1'" prop="lnglat" label="经纬" align="center">
        </el-table-column> -->
        <el-table-column prop="time" label="时间" align="center">
        </el-table-column>
        <el-table-column prop="content" label="日志" align="center">
        </el-table-column>
      </el-table>
    </div>
    <el-row class="paging" align="middle" type="flex" justify="end">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="pagesize"
        layout="prev,total, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { equipmentlog, logInfo ,logpages} from "../../api/equipmentLogapi";
export default {
  components: {},
  data() {
    return {
      loading:false,
      idcode: this.$route.params.code,
      companyid: this.$route.params.companyid,
       selectcompanyid:null,
       useroptions: [{
          id: '',
          text: ''
        }],
        id: '',
      /**级联选择设备 */
      defaultval: [],
      options: [],
      id: null,
      optionProps: {
        checkStrictly: false,
        value: "id",
        label: "text",
        children: "children",
      },
      eqmid: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: "",
      value2: "",
      tableData: [],
      currentPage3: 1,
      pagesize: 12,
      total: 32,
    };
  },
   created() {
    this.userRoleId = this.$cookies.get("userRoleId");
  },
  mounted() {
    this.loading=true
    let selectcode = this.idcode;
    if (selectcode) {
      this.frommapdata();
       if (this.userRoleId=='1') {
        this.defaultval = [this.companyid, this.idcode];
      } 
      this.eqmid = this.idcode;
      selectcode = "";
    } else {
      this.getequipmentlog();
    }
  },
  methods: {
    frommapdata() {
      if (this.idcode != "") {
        logInfo(this.idcode, this.value2[0], this.value2[1])
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
               this.useroptions=res.data.casecade[0].children
              this.selectcompanyid=res.data.casecade[0].id
              this.options = res.data.casecade;
              this.tableData = res.data.logs;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
              this.loading = false;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      } else {
      }
    },
    /**获取设备日志信息 */
    getequipmentlog() {
      equipmentlog()
        .then((res) => {
          console.log(res);
          console.log("这是设备日志");
          this.loading=false
           this.useroptions=res.data.casecade[0].children
              this.selectcompanyid=res.data.casecade[0].id
          this.options = res.data.casecade;
          this.tableData = res.data.logs;
          this.pagesize = res.data.page_size;
          this.total = res.data.total_pages;
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    /**级联选择设备
     */
     userselecteqm(e){
          console.log(e,'用户用户')
          this.eqmid = e
    },
    handleChange(e) {
      console.log(e);
      this.eqmid = e[1];
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
   handleCurrentChange(val) {
        if (this.value2) {
        logInfo(this.eqmid, this.value2[0], this.value2[1],val)
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.logs;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      } else {
        logInfo(this.eqmid, "", "",val)
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.logs;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      }
      // logpages(val)
      //   .then((res) => {
      //     if (res.status == 200) {
      //       console.log(res);
      //       this.tableData = res.data.logs;
      //       this.pagesize = res.data.page_size;
      //       this.total = res.data.total_pages;
      //     }
      //   })
      //   .catch((e) => {
      //     this.$message.error(e.message);
      //   });
      // console.log(`当前页: ${val}`);
    },
    selectcode(e) {
      console.log("这是选择的设备号");
      console.log(e);
    },
    serachlog() {
      console.log("查询时间");
      this.currentPage3=1
      if (this.value2) {
        logInfo(this.eqmid, this.value2[0], this.value2[1],'')
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.logs;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      } else {
        logInfo(this.eqmid, "", "",'')
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.logs;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      }
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 1920px) {
  .eqmlog{
    /* background-color: darkblue; */
    padding: 1.25rem 1.875rem 0 1.875rem;
    /* height: 752px;
    overflow-y: auto; */
  }
.paging {
  
  width: 70%;
  float: right;
  /* background-color: chocolate; */
  text-align: end;
  margin-top: 1.25rem;
}
.search {
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
  font-size: 0.875rem;
  /* border: 0.0625rem solid black; */
  background-color: #EBEFF5;
  border-radius: 0.625rem;
 
   text-align: center;
}
.search_item{
  /* background-color: darkorchid; */
  text-align: center;
}
.el-table{
  margin-top: 1.25rem;
}
}
@media only screen and (max-width: 1080px) {
  .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner {
    width: 13rem!important;
}
.eqmlog{
    /* background-color: darkblue; */
    padding: 0.625rem 0.625rem 0 0.625rem;
  }
.paging {
  
 width: 70%;
  float: right;
  /* background-color: chocolate; */
  text-align: end;
  margin-top: 0.625rem;
}
.search {
  padding: 0.25rem 0.3125rem 0.25rem 0.3125rem;
  font-size: 0.875rem;
  /* border: 0.0625rem solid black; */
  background-color: #EBEFF5;
  border-radius: 0.625rem;
 
   text-align: center;
}
.search_item{
  /* background-color: darkorchid; */
  text-align: center;
}
.el-table{
  margin-top: 0.625rem;
}
}


</style>